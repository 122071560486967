//
//
//
//

export default {
  name: "LoginSvg",
  props: {
    width: {type: Number, default: 500},
    height: {type: Number, default: 500}
  }
}
